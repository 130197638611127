<template>
  <div class="empty">
    <el-empty description="您还未拥有集群。不必着急，创建一个:)">
      <el-button disabled type="primary" @click="this.$router.push('/form/channel')">创建新集群</el-button>
      <el-button type="primary" @click="enterIDEFree">免费体验</el-button>
    </el-empty>
  </div>
</template>

<script>
export default {

  methods:{
    enterIDEFree(){
      window.location.href = "https://alphaide-demo.dmetasoul.com";
    }
  }
};
</script>

<style>
</style>