<template>
  <div class="clusterLsit" style="background-color: white">
    <!-- 用户试用集群，置顶 -->
    <el-row>
      <el-col :span="12">
        <el-card
          shadow="hover"
          style="margin: 3px; border-width: 2px; border-left-color: #5cc49f"
        >
          <el-descriptions class="margin-top" :column="2" border>
            <template #extra>
              <el-button type="primary" @click="enterIDE('xxxx', 'jinshan')">
                进入IDE(试用版)
                </el-button
            ></template>

            <el-descriptions-item>
              <template #label>
                <div class="cell-item">集群名称</div>
              </template>
              试用集群
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">云平台</div>
              </template>
              <el-tag size="small"> huawei </el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">状态</div>
              </template>
              <p style="color:#5cc49f">◉</p>运行中
            </el-descriptions-item>
          </el-descriptions>
          <!-- <el-alert
        v-if="!cluster.finished"
        title="该集群尚未配置服务"
        type="warning"
        center
        show-icon
      >
      </el-alert> -->
        </el-card>
      </el-col>
    </el-row>
    <div v-if="show">
      <div v-if="CreatingList.length">
        <el-row>
          <el-col
            :span="12"
            v-for="(CreatingCluster, index) in CreatingList"
            :key="index"
          >
            <!-- <el-card shadow="hover" :body-style="{ background: '#FFEE99' }" style="margin:3px;"> -->
            <el-card
              shadow="hover"
              style="margin: 4px; border-width: 2px; border-left-color: #00bbff"
            >
              <el-descriptions class="margin-top" :column="2" border>
                <template #extra>
                  <!-- 创建中的集群项不具有删除功能 但可以查询进度-->
                  <el-button
                    type="primary"
                    @click="enterDetailPage(CreatingCluster.clusterPrimaryKey)"
                  >
                    配置详情
                    <van-icon name="comment-o"
                  /></el-button>
                </template>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">集群名称</div>
                  </template>
                  {{ CreatingCluster.clusterName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">云平台</div>
                  </template>
                  <el-tag size="small">
                    {{ CreatingCluster.cloud }}
                  </el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">状态</div>
                  </template>
                  <p :style="'color:'+cluster.statusTextColor">◉</p>{{ CreatingCluster.status }}
                </el-descriptions-item>
              </el-descriptions>
              <el-progress
                :stroke-width="20"
                :percentage="CreatingCluster.processRate"
              />
              <!-- <el-alert
            v-if="!CreatingCluster.finished"
            title="该集群尚未配置服务"
            type="warning"
            center
            show-icon
          >
          </el-alert> -->
            </el-card></el-col
          >
        </el-row>
      </div>
      <div v-if="ClusterFaildList.length">
        <el-row>
          <el-col
            :span="12"
            v-for="(cluster, index) in ClusterFaildList"
            :key="index"
          >
            <el-card
              shadow="hover"
              style="margin: 4px; border-width: 2px; border-left-color: #f6d342"
            >
              <el-descriptions class="margin-top" :column="2" border>
                <template #extra>
                  <el-button
                    v-if="!!cluster.finished"
                    type="primary"
                    @click="enterDetailPage(cluster.clusterPrimaryKey)"
                  >
                    配置详情
                    <van-icon name="comment-o"
                  /></el-button>
                  <el-button
                    v-if="!!cluster.finished"
                    type="danger"
                    @click="deleteCluster(cluster.clusterPrimaryKey)"
                  >
                    删除
                    <van-icon name="delete-o"
                  /></el-button>
                </template>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">集群名称</div>
                  </template>
                  {{ cluster.clusterName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">云平台</div>
                  </template>
                  <el-tag size="small">
                    {{ cluster.cloud }}
                  </el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">状态</div>
                  </template>
                  <p :style="'color:'+cluster.statusTextColor">◉</p>{{ cluster.status }}
                </el-descriptions-item>
              </el-descriptions>
              <el-alert
                v-if="!cluster.finished"
                title="该集群尚未配置服务"
                type="warning"
                center
                show-icon
              >
              </el-alert>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div v-if="ClusterList.length">
        <el-row>
          <el-col
            :span="12"
            v-for="(cluster, index) in ClusterList"
            :key="index"
          >
            <el-card
              shadow="hover"
              style="margin: 3px; border-width: 2px; border-left-color: #5cc49f"
            >
              <el-descriptions class="margin-top" :column="2" border>
                <template #extra>
                  <el-button
                    v-if="!!cluster.finished"
                    type="primary"
                    @click="enterIDE(cluster.clusterPrimaryKey, cluster.cloud)"
                  >
                    进入IDE
                    <van-icon name="comment-o"
                  /></el-button>
                  <el-button
                    v-if="!!cluster.finished"
                    type="primary"
                    @click="enterDetailPage(cluster.clusterPrimaryKey)"
                  >
                    配置详情
                    <van-icon name="comment-o"
                  /></el-button>
                  <el-button
                    v-if="!!cluster.finished"
                    type="danger"
                    @click="deleteCluster(cluster.clusterPrimaryKey)"
                  >
                    删除
                    <van-icon name="delete-o"
                  /></el-button>
                </template>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">集群名称</div>
                  </template>
                  {{ cluster.clusterName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">云平台</div>
                  </template>
                  <el-tag size="small">
                    {{ cluster.cloud }}
                  </el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">状态</div>
                  </template>
                  <p :style="'color:'+cluster.statusTextColor">◉</p>{{ cluster.status }}
                </el-descriptions-item>
              </el-descriptions>
              <!-- <el-alert
            v-if="!cluster.finished"
            title="该集群尚未配置服务"
            type="warning"
            center
            show-icon
          >
          </el-alert> -->
            </el-card>
          </el-col>
        </el-row>
      </div>

      <el-card shadow="hover" style="width: 20%; margin: 3px"
        ><el-button disabled type="text" @click="this.$router.push('/form/channel')"
          ><van-icon name="add-o" />创建新集群
        </el-button></el-card
      >
    </div>
    <div v-else>
      <NoData />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NoData from "./NoData.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import constant from "../../assets/constant";
import decode from "jwt-decode";

export default {
  created() {
    this.getAllClusters();
  },
  mounted() {
    if (!this.$store.getters.intervalFlag) {
      let getStatus = window.setInterval(this.getAllClusters, 30000);
      this.timerId = getStatus;
    }
  },
  beforeUnmount() {
    clearInterval(this.timerId);
  },
  data() {
    return {
      ClusterList: new Array(),
      CreatingList: new Array(),
      ClusterFaildList: new Array(),
      show: false,
      timerId: 0,
    };
  },
  components: {
    NoData,
  },
  methods: {
    enterDetailPage(clusterPrimaryKey) {
      this.$router.push({
        name: "buildingStatus",
        params: { clusterPrimaryKey: clusterPrimaryKey },
      });
    },
    enterIDE(clusterPrimaryKey, cloud) {
      //试用集群临时逻辑
      if (clusterPrimaryKey === "xxxx") {
        window.location.href = "https://alphaide-demo.dmetasoul.com";
      } else {
        window.location.href =
          "https://" + clusterPrimaryKey + "." + cloud + ".dmetasoul.com/";
      }
      // axios({
      //   method: "get",
      //   url: "/api/driver/getStudio",
      //   params: { clusterPrimaryKey: clusterPrimaryKey },
      // })
      //   .then((res) => {
      //     window.location.href = res.data.data.url;
      //   })
      //   .catch((err) => {
      //     this.$message({
      //       message: "IDE跳转失败",
      //       type: "error",
      //     });
      //   });
      // }
    },
    deleteCluster(primaryKey) {
      ElMessageBox.confirm(
        constant.INFORM_MESSAGE.DELETE_CLUSTER_INFORM,
        "删除提示",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          console.log(primaryKey);
          try {
            axios({
              method: "post",
              url: "/api/driver/deleteById",
              data: { clusterPrimaryKey: primaryKey },
            }).then((res) => {
              console.log(JSON.stringify(res.data));
              if (res.data.data === true) {
                ElMessage({
                  type: "success",
                  message: "集群删除成功",
                });
                this.getAllClusters();
              } else {
                ElMessage({
                  type: "error",
                  message: "集群删除失败: 集群不存在或已被删除",
                });
              }
            });
          } catch (e) {
            console.log(JSON.stringify(e));
            ElMessage({
              type: "error",
              message: "集群删除失败:" + e.message,
            });
          }
        })
        .catch(() => {});
    },
    getAllClusters() {
      try {
        let ClusterListTemp = new Array();
        let CreatingListTemp = new Array();
        let ClusterFaildListTemp = new Array();
        axios({
          method: "get",
          url:
            "/api/driver/getAllClusters?userId=" +
            encodeURI(decode(localStorage.getItem("eleToken")).userId),
        }).then((res) => {
          res.data.data.forEach((cluster) => {
            console.log(cluster.status);
            switch (cluster.status) {
              case "running":
                ClusterListTemp.push({
                  clusterPrimaryKey: cluster.clusterPrimaryKey,
                  clusterName: cluster.clusterName,
                  cloud: cluster.cloud,
                  status: "运行中",
                  finished: true,
                  processRate: cluster.processRate,
                  processDescribe: cluster.processDescribe,
                  statusTextColor: "#5cc49f"
                });
                break;
              case "initialing":
                CreatingListTemp.push({
                  clusterPrimaryKey: cluster.clusterPrimaryKey,
                  clusterName: cluster.clusterName,
                  cloud: cluster.cloud,
                  status: "创建中:" + cluster.processDescribe + "...",
                  finished: false,
                  processRate: cluster.processRate,
                  processDescribe: cluster.processDescribe,
                  statusTextColor: "#00bbff"
                });
                break;
              case "initialingFaild":
                ClusterFaildListTemp.push({
                  clusterPrimaryKey: cluster.clusterPrimaryKey,
                  clusterName: cluster.clusterName,
                  cloud: cluster.cloud,
                  status:
                    "创建失败,失败步骤: " +
                    cluster.processDescribe +
                    "(" +
                    cluster.processRate +
                    "%)",
                  finished: true,
                  processRate: cluster.processRate,
                  processDescribe: cluster.processDescribe,
                  statusTextColor: "#f6d342"
                });
                break;
              default:
                break;
            }
          });
          this.ClusterList = ClusterListTemp;
          this.CreatingList = CreatingListTemp;
          this.ClusterFaildList = ClusterFaildListTemp;
          this.show =
            !!this.ClusterList.length ||
            !!this.CreatingList.length ||
            !!this.ClusterFaildList.length
              ? true
              : false;
        });
      } catch (e) {
        console.error(e.response);
      }
    },
  },
};
</script>

<style>
p{
  display: inline-block;
  margin-right: 3px;
}
</style>